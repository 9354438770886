import { Storage } from "@/util/enum/storage.enum";

export interface IClientSessionIdManagment {
  botId: string;
  clientSessionId: string;
  timestamp: number;
}

export class ClientSessionIdManagment {
  botId: string;
  constructor(_botId: string) {
    this.botId = _botId;
  }

  getKey(): string {
    return `${Storage.LOCALSTORAGE_LOUISEBOT_WIDGET}${this.botId}`;
  }

  getSessionId(): IClientSessionIdManagment | null {
    const data = localStorage.getItem(this.getKey());
    if (data) {
      return JSON.parse(data);
    } else {
      return null;
    }
  }
  removeSession(): void {
    localStorage.removeItem(this.getKey());
  }

  setSessionId(sessionId: string): void {
    const storeSessionData = this.getSessionId();
    if (storeSessionData && storeSessionData.clientSessionId) {
      return;
    }
    if (sessionId) {
      localStorage.setItem(
        this.getKey(),
        JSON.stringify({
          botId: this.botId,
          clientSessionId: sessionId,
          timestamp: new Date().getTime()
        } as IClientSessionIdManagment)
      );
    }
  }
}
